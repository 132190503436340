<template>
    <div class="top">
        <div v-for="(item, index) in list" :key="index" class="sin">
            <div class="rangeNum">NO.{{index + 1}}</div>
            <div class="name">{{item.name}}</div>
            <div class="rangeCon">
                <div :style="{width: (Number(item.num)/total) * 100 + '%'}" class="range"></div>
            </div>
            <div class="num">{{item.num}}</div>
        </div>
        <!-- <div class="sin">
            <div class="rangeNum">NO.1</div>
            <div class="name">追风筝的人追风筝的人追风筝的人追风筝的人追风筝的人追风筝的人</div>
            <div class="rangeCon">
                <div class="range"></div>
            </div>
            <div class="num">688</div>
        </div>
        <div class="sin">
            <div class="rangeNum">NO.1</div>
            <div class="name">追风筝的人追风筝的人追风筝的人追风筝的人追风筝的人追风筝的人</div>
            <div class="rangeCon">
                <div class="range"></div>
            </div>
            <div class="num">688</div>
        </div>
        <div class="sin">
            <div class="rangeNum">NO.1</div>
            <div class="name">追风筝的人追风筝的人追风筝的人追风筝的人追风筝的人追风筝的人</div>
            <div class="rangeCon">
                <div class="range"></div>
            </div>
            <div class="num">688</div>
        </div> -->
    </div>
</template>

<script>
import {getTopBooks} from '../../../api/index.js'
export default {
    name: '',
    props: {
        syncId: String
    },
    data() {
        return {
            list: [],
            total: 0
        }
    },
    methods: {
    },
    mounted() {
        if (!this.syncId) return
        getTopBooks({syncId:this.syncId}).then(res => {
            this.list = res.data
            if (this.list.length) {
                this.total = Number(this.list[0].num)
            }
        })
    },
}
</script>

<style lang="less">
.top{
    margin-top: 1em;
    .sin{
        height: 2.3em;
        padding: 0 0.7rem;
        background: url(../../../assets/images/frame_range.png) no-repeat center;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        margin-top: 10px;
        &:first-child {
            .rangeNum{
                background: url(../../../assets/images/range_no1@2x.png) no-repeat center;
                background-size: 100% 100%;
            }
            .range{
                background: linear-gradient(90deg, rgba(255,74,34,0.1) 0%, #FF4A22 100%)!important; ;
            }
        }

        &:nth-child(2) {
            .rangeNum{
                background: url(../../../assets/images/range_no2@2x.png) no-repeat center;
                background-size: 100% 100%;
            }
            .range{
                background: linear-gradient(90deg, rgba(255,205,62,0.1) 0%, #FFCD3E 100%)!important; ;
            }
        }   
        &:nth-child(3) {
            .rangeNum{
                background: url(../../../assets/images/range_no3@2x.png) no-repeat center;
                background-size: 100% 100%;
            }
            .range{
                background: linear-gradient(90deg, rgba(62,243,255,0.1) 0%, #3EF3FF 100%)!important;           
            }
        }
        .rangeNum{
            color: #fff;
            margin-left: 0px;
            font-size: 1.2em;
            width: 3.5em;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .name{
            color:#fff;
            font-size: 1em;
            padding-left: 0.5em;
            width: 30%;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis
        }
        .rangeCon{
            position: relative;
            background: rgba(27,126,242,0.5);
            height: 50%;
            flex: 1;
            margin: 0 10px 0 30px;
            .range{
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                // width: 50%;
                background: linear-gradient(90deg, rgba(27,126,242,0.1) 0%, #1B7EF2 100%);
border-radius: 0px 0px 0px 0px;
            }
        }
        .num{
            padding-left: 0.2em;
            color: #fff;
            font-size: 0.9em;
        }
    }
}
</style>